import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

const Title = 'REGENiTECH Fund Corp.'
const HomeDesc = `Welcome to ${Title}, dedicated to the development of environmentally responsible technologies that support regenerative agriculture and renewable energy production.`
const ThankYouDesc = `The Thank You page for ${Title}`
const ContactUsDesc = `Contact Us page for ${Title}`
const PrivacyPolicyDesc = `The privacy policy page for ${Title}`

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: Title,
        metaTags: [
          {
            name: 'description',
            content: HomeDesc
          },
          {
            property: 'og:description',
            content: HomeDesc
          }
        ]
      }

    },
    // Remember to update vue.config.js with all routes
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import(/* webpackChunkName: "about" */ './views/ContactUs.vue'),
      meta: {
        title: Title + ' - Contact Us',
        metaTags: [
          {
            name: 'description',
            content: ContactUsDesc
          },
          {
            property: 'og:description',
            content: ContactUsDesc
          }
        ]
      }
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import(/* webpackChunkName: "about" */ './views/PrivacyPolicy.vue'),
      meta: {
        title: Title + ' - Privacy Policy',
        metaTags: [
          {
            name: 'description',
            content: PrivacyPolicyDesc
          },
          {
            property: 'og:description',
            content: PrivacyPolicyDesc
          }
        ]
      }
    },
    {
      path: '/thank-you',
      name: 'thank-you',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/ThankYou.vue'),
      meta: {
        title: Title + ' - Thank You',
        metaTags: [
          {
            name: 'description',
            content: ThankYouDesc
          },
          {
            property: 'og:description',
            content: ThankYouDesc
          }
        ]
      }
    }
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
  ]
})
