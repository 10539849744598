<template>
  <div class="regen-footer">
    <div class="regen-products" role="contentinfo">
      <div class="d-block d-md-inline-block">
        <strong class="regen-name"><span class="regeni">REGENi</span>BOTS<sup>&trade;</sup></strong> <span
        class="d-block d-sm-inline-block"><span class="d-none d-sm-inline">&nbsp;&ndash;</span> Smart bioreactor technology</span>
      </div>
      <div class="regen-bullet d-none d-md-inline">&bullet;</div>
      <div class="d-block d-md-inline-block mt-2 mt-sm-0">
        <strong class="regen-name"><span class="regeni">REGENi</span>GROWN<sup>&trade;</sup></strong> <span
        class="d-block d-sm-inline-block"><span class="d-none d-sm-inline">&nbsp;&ndash;</span> Regenerative agricultural solutions</span>
      </div>
      <div class="regen-bullet d-none d-xl-inline">&bullet;</div>
      <div class="d-block d-md-inline-block mt-2 mt-sm-0">
        <strong class="regen-name"><span class="regeni">REGENi</span>SMART<sup>&trade;</sup></strong> <span
        class="d-block d-sm-inline-block"><span class="d-none d-sm-inline">&nbsp;&ndash;</span> Nothing Artificial, all NATURAL INTELLIGENCE</span>
      </div>
    </div>
    <div class="regen-copyright">
      &copy; 2019 - {{getYear}} {{companyName}}. All rights reserved.
    </div>
    <div class="globe d-none d-md-block"></div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Footer',
    computed: {
      ...mapState(['companyName']),
      getYear () {
        return new Date().getFullYear()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .regen-footer {
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      //@media (min-width: 540px) {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: calc(50vw - 100px);
    }

    @include media-breakpoint-up(lg) {
      height: calc(33.333vw - 100px);
    }

    .regen-products {
      background: white;
      position: relative;
      width: 100vw;
      padding-bottom: 40px;
      padding-top: 20px;
      text-align: center;
      z-index: 300;
      @include clearfix;

      @include media-breakpoint-up(sm) {
        background: rgba($black, 0.05);
        backdrop-filter: blur(8px);
        position: absolute;
        bottom: 0;
      }

      .regen-list {
        text-align: left;
        margin: 0;
      }

      .regen-bullet {
        margin: 0 10px;
      }

      * {
        color: lighten($black, 10);
      }
    }

    .regen-copyright {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 300;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 1.75rem;
      background: $gray-800;
      color: $gray-200;
      font-size: 0.6rem;
      height: 20px;
    }

    .globe {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 50vw;
      width: 50vw;
      transform: translate3d(40px, 60px, 0);
      z-index: 101;
      margin: 0;
      padding: 0;
      line-height: 0;
      background: url("~@/assets/images/globe-bg.svg") no-repeat calc(100% + 20px) calc(100% + 50px);
      background-size: cover;
      @include media-breakpoint-up(md) {
        background: url("~@/assets/images/globe-bg.svg") no-repeat calc(100% + 20px) calc(100% + 50px);
        background-size: cover;
      }
      @include media-breakpoint-up(lg) {
        height: 33.333vw;
        width: 33.333vw;
      }
    }
  }
</style>
