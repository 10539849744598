<template>
  <span class="regen-name"><span class="regeni">REGENi</span>TECH Fund Corp </span>
</template>

<script>
export default {
  name: 'CompanyName'
}
</script>

<style lang="scss">
.regen-name {
  font-weight: normal;
  font-style: italic;
  color: $gray-900;

  sup {
    color: $black;
  }

  .regeni {
    color: lighten($teal, 5);
  }

  &.light {
    color: $gray-200;

    .regeni {
      color: $teal-6;
    }
  }
}
</style>
