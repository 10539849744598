<template>
  <div class="container align-items-center justify-content-center regen-welcome mt-5">
    <h1 class="subtitle mt-3"> Welcome to <strong class="regen-name"><span class="regeni">REGENi</span>TECH Fund
      Corp.</strong></h1>
    <div class="mt-2">While <company-name/> is focused in developing new closed-loop technologies to support regenerative
      farming and renewable energy, we understand the urgency of our collapsing ecosystem. We believe the restoration of
      our agriculture soils remains one of the best tools we have for fixing our climate as well as our broken food and
      healthcare systems. Thus we have created <company-name/> to help support the conversion of small farms to
      regenerative farming practices using organic inputs and technologies from a variety of sources.
    </div>

    <div class="mt-5 mb-4">If you are interested in supporting regenerative farming you can send your tax deductible
      donation to <br> <company-name/> <address>P.O. Box 1526, Whitefish, MT 59937</address>.
    </div>

    <div class="mt-4 mb-2">
      Or you can make a contribution online:
    </div>

    <div class="mt-4 mb-2">
      <p>via PayPal</p>
      <pay-pal-button></pay-pal-button>
      <br>
      <p>via Park Side</p>
      <a class="donate-btn" href="http://weblink.donorperfect.com/Regenitech" target="_blank">DONATE</a>
      <img src="https://interland3.donorperfect.net/images/cc_logo_vmda.gif" alt="image of accepted credit card icons">
    </div>

    <hr class="mt-5">

    <div class="mt-4">
      If you are a farmer interested in converting to regenerative agriculture please
      <router-link to="/contact-us" class="underlined">send us your information</router-link>
      and we will do our best to help.
    </div>

    <hr class="mt-4">
  </div>
</template>

<script>
import CompanyName from '../components/CompanyName'
import PayPalButton from '../components/PayPalButton'
export default {
  name: 'home',
  components: {
    CompanyName,
    PayPalButton
  }
}
</script>

<style lang="scss">
.container {
  position: relative;
  z-index: 200;
}

.regen-welcome {
  text-align: center;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  z-index: 200;
}

address {
  display: inline;
  color: lighten($black, 10);
}

h2 {
  /*margin-bottom: 60px;*/
}

h4 {
  font-weight: normal;
}

.subtitle {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  font-size: 6vw;
  @include media-breakpoint-up(sm) {
    font-size: 36px;
  }
}

.donate-btn {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.4em;

  background-color: teal;
  color: white;
  border-radius: 20px;
  height: 40px;
  max-width: 180px;
  padding: 6px 12px;
  margin: 0 auto 10px;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: #00c5c5;
    color: black;
    text-decoration: none;
  }
}
</style>
