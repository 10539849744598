import Vue from 'vue'
import Vuex from 'vuex'
import querystring from 'querystring'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    companyName: 'REGENiTECH Fund Corp',
    companyAddress: '333 Lupfer Ave\n Whitefish, MT 59937',
    formCaptureApi: 'https://api.regenitech.com',
    formSubmitted: false
  },
  actions: {
    submitForm: ({ commit, state }, data) => {
      Vue.axios.post(state.formCaptureApi, querystring.stringify(data))
      .then((response) => {
        // console.log('response', response.data)
        if (response.data.ok) {
          commit('submitFormMut', data)
        }
      })
    }
  },
  mutations: {
    submitFormMut (state) {
      state.formSubmitted = true
    }
  }
})
