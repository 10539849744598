<template>
<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_s-xclick" />
<input type="hidden" name="hosted_button_id" value="347TJB972RWEJ" />
<input type="image" src="https://regenitechfund.org/img/regenitech-donate.svg" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
</form>
</template>

<script>
  export default {
    name: 'PayPalButton',
    props: {
      account: {
        type: String,
        default: 'michael@regenitech.com'
      },
      itemName: {
        type: String,
        default: 'REGENiTECH Fund Corp'
      },
      buttonImage: {
        type: String,
        default: 'https://regenitechfund.org/img/regenitech-donate.svg'
      }
    }
  }
</script>

<style scoped>

</style>
