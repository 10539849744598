<template>
  <div id="app">
    <div class="regen-header d-flex flex-row align-items-center justify-content-center">
      <regenitech-logo theme="dark"/>
    </div>
    <b-navbar sticky toggleable="lg" type="dark" variant="dark" role="navigation">
      <b-navbar-brand to="/">
        <strong class="regen-name light"> <span class="regeni">REGENi</span>TECH FUND CORP.</strong>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"/>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="https://www.regenitech.com" target="_blank">REGENiTECH LLC</b-nav-item>
          <b-nav-text tag="li"> |</b-nav-text>
          <b-nav-item to="/contact-us">CONTACT US</b-nav-item>
          <b-nav-item href="https://www.facebook.com/regenitech/" target="_blank">
            <unicon class="social-icon" name="facebook" fill="currentColor" title="Facebook"/>
            <span class="d-inline d-lg-none ml-2">Facebook</span>
          </b-nav-item>
          <b-nav-item href="mailto:info@regenitech.com" target="_blank">
            <unicon class="social-icon" name="at" fill="currentColor" title="email"/>
            <span class="d-inline d-lg-none ml-2">Contact Us</span>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view role="main"/>

    <regen-footer/>
  </div>
</template>

<script>
  import RegenitechLogo from '@/components/RegenitechLogo'
  import RegenFooter from './components/RegenFooter'

  export default {
    name: 'app',
    components: {
      RegenitechLogo,
      RegenFooter
    },
    methods: {
      getVh () {
        let vh = window.innerHeight * 0.01
        // document.documentElement.style.setProperty('--vh', `${vh}px`)
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.getVh()
        window.addEventListener('resize', this.getVh)
      })
    }
  }
</script>

<style lang="scss">
  html, body {
    height: 100vh;
    height: calcHeight(100);
    width: 100vw;
    margin: 0;
  }

  html {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }

  body {
    overflow: hidden;
  }

  * {
    color: $gray-700;

    &,
    &:before,
    &:after {
      box-sizing: border-box;
      margin: 0;
    }
  }

  .underlined {
    text-decoration: underline;
  }

  #app {
    height: 100vh;
    height: calcHeight(100);
    overflow-x: hidden;
    overflow-y: scroll;
    @include media-breakpoint-up(sm) {
      padding-bottom: 130px;
    }

    .social-icon {
      color: darken($white, 10);
      fill: darken($white, 10);

      &:hover {
        color: $white;
        fill: $white;
      }
    }

    .white-blur {
      backdrop-filter: blur(10px);
    }

    .pill-box {
      background: rgba($gray-200, 0.5);
      backdrop-filter: blur(12px);
      border-radius: 12px 6px;
      padding: 12px;

      hr {
        display: none;
      }
    }

    .regen-logo {
      max-width: 60%;
    }

    .regen-header {
      $circuitWidth: 456px;
      $circuitWidthHalf: math.div($circuitWidth, 2);
      position: sticky;
      top: 0;
      height: 12vh;
      height: calcHeight(12);
      z-index: 202;
      background: linear-gradient(to bottom,
        $teal-8 0%,
        $teal-7 10%,
        $teal-6 20%,
        $teal-5 30%,
        $teal-4 40%,
        $teal-3 50%,
        $teal-2 60%,
        $teal-1 100%);
      box-shadow: 0 5px 20px $gray-400;

      @include media-breakpoint-up(sm) {
        background: url("~@/assets/images/header-circuit-left.svg") no-repeat $circuitWidthHalf*-0.4 50%,
        url("~@/assets/images/header-circuit-right.svg") no-repeat calc(100vw - #{$circuitWidthHalf*0.6}) 50%,
        linear-gradient(to bottom,
            $teal-8 0%,
            $teal-7 10%,
            $teal-6 20%,
            $teal-5 30%,
            $teal-4 40%,
            $teal-3 50%,
            $teal-2 60%,
            $teal-1 100%);
        background-size: $circuitWidthHalf, $circuitWidthHalf, cover;
      }
      @include media-breakpoint-up(md) {
        height: 188px;
        background: url("~@/assets/images/header-circuit-left.svg") no-repeat $circuitWidth*-0.66 50%,
        url("~@/assets/images/header-circuit-right.svg") no-repeat calc(100vw - #{$circuitWidth*0.33}) 50%,
        linear-gradient(to bottom,
            $teal-8 0%,
            $teal-7 10%,
            $teal-6 20%,
            $teal-5 30%,
            $teal-4 40%,
            $teal-3 50%,
            $teal-2 60%,
            $teal-1 100%)
      }
      @include media-breakpoint-up(lg) {
        height: 188px;
        background: url("~@/assets/images/header-circuit-left.svg") no-repeat $circuitWidth*-0.5 50%,
        url("~@/assets/images/header-circuit-right.svg") no-repeat calc(100vw - #{$circuitWidth*0.5}) 50%,
        linear-gradient(to bottom,
            $teal-8 0%,
            $teal-7 10%,
            $teal-6 20%,
            $teal-5 30%,
            $teal-4 40%,
            $teal-3 50%,
            $teal-2 60%,
            $teal-1 100%)
      }
      @include media-breakpoint-up(xl) {
        background: url("~@/assets/images/header-circuit-left.svg") no-repeat $circuitWidth*-0.33 50%,
        url("~@/assets/images/header-circuit-right.svg") no-repeat calc(100vw - #{$circuitWidth*0.66}) 50%,
        linear-gradient(to bottom,
            $teal-8 0%,
            $teal-7 10%,
            $teal-6 20%,
            $teal-5 30%,
            $teal-4 40%,
            $teal-3 50%,
            $teal-2 60%,
            $teal-1 100%)
      }

      @media (orientation: landscape) {
        height: 24vh;
      }
    }

    .navbar {
      top: 12vh;
      top: calcHeight(12);
      @include media-breakpoint-up(md) {
        top: 188px;
      }
      @media (orientation: landscape) {
        /*padding-left: env(safe-area-inset-left);*/
        /*padding-right: env(safe-area-inset-right);*/
        top: 24vh;
      }
    }
  }
</style>
